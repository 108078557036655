@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: var(--vermilion);
  --secondary: var(--wild-sand);
  --main-text-color: var(--cod-gray);
  --list-title-color: var(--dodger-blue);
  --list-company-color: var(--cod-gray);
  --list-loc-color: var(--cod-gray);
  --visited-color: var(--purple-heart);

  /* profile */
  --main-border-color: var(--mercury1);
  --custom-weight: 700;
  --page-text-color: #000;
  --page-text-color2: var(--jumbo);
  --main-border-radius: 24px;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  background-color: #fff;
  color: var(--main-text-color);
}

.container {
  max-width: 992px !important;
  margin: auto;
}

.hidden {
  display: none;
}

.custom-shadow-dc {
  box-shadow: 0px 12px 24px -20px rgba(0, 0, 0, 0.1);
}

.header {
  box-shadow: inset 0px -36px 16px -10px rgba(0, 0, 0, 0.1);
}

.custom-shadow-dc-2 {
  box-shadow: 0px 0px 12px 0px #00000029;
}

.header-menu-icon {
  box-shadow: 0px 0px 12px 0px #0000001f;
}

.header-menu-icon-with-hover {
  &:hover {
    box-shadow: 0px 0px 12px 0px #0000001f;
  }
}

.homepage-header-background {
  background: url('/dc/homepage-header-background.svg') repeat-x top center;
}

.signup-background {
  background: url('/dc/signup-background.png') no-repeat top left;
  background-size: 100% 444px;
}

.signup-shadow {
  box-shadow: 2px -2px 17.2px 0px #8a8a8a26;
}

.homepage-footer-shadow {
  @media only screen and (min-width: 769px) {
    box-shadow: 24px 0px 24px -20px rgba(0, 0, 0, 0.1), -24px 0px 24px -20px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 1024px) {
  .main {
    padding: 5rem 0;
  }
}

.popup-bg-blur-dc {
  backdrop-filter: blur(5.699999809265137px);
  background: rgba(0, 0, 0, 0.64);
}
